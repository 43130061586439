@import '../../assets/styles/_variables';
.expandButton {
    background-color: $button-color;
    overflow: hidden;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: bold;
    display: inline-block;
    // white-space: nowrap;
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
    color: $button-text;
    margin-top: 0.3rem;
}

.iconExpansion {
    margin: 8px;
}

.collapsibleSection {
    border-radius: 4px;
    background-color: $base-lighter;
    padding: 0.5rem;
    margin-bottom: .1rem;
}