// Colors
// General
$color-black: #202020;
$color-white: #f0f0f0;
$base-color: #c8e1d8;
$base-darker: darken($base-color, 20%);
$base-lighter: lighten($base-color, 10%);
// Header
$header-color: darken($base-color, 60%);
$header-color-lighter: lighten($header-color, 10%);
// Buttons
$button-color: #d3efdf;
$button-text: invert($button-color);
// Links
$link-color: darken($base-color, 50%);
$link-color-hover: lighten($link-color, 25%);
// Formatting
$common-border-radius: 5px;