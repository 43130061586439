@import 'assets/styles/_variables';

/* App component */

#divMainContent {
    background-color: #fffef5;
    max-width: 1000px;
    min-width: 350px;
    border-radius: $common-border-radius;
    padding: 1.5rem;
    margin: 0 auto;
}

.innerContent {
    min-height: calc(95vh - 70px);
}

nav {
    a {
        padding: 0.25rem .75rem;
        text-decoration: none;
        margin: .5rem;
        display: inline-block;
        background-color: $base-darker;
        border-radius: 4px;
    }
    a:visited,
    a:link {
        color: $color-black;
    }
    a:hover {
        color: $color-white;
        text-decoration: underline;
    }
}

footer {
    font-size: 0.8rem;
    background-color: $base-color;
    padding: 0.9rem;
    border-radius: $common-border-radius;
    height: 15px;
}

.noDecorationList {
    list-style-type: none;
    padding-left: 0.5rem;
}